//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GradientImage',
  props: {
    left: Boolean,
    imgSrc: {
      type: String,
      default: '//summitproedu.imgix.net/landing-pages/pt-phippf.jpg?auto=enhance&fit=crop,faces',
    },
  },
};
